import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ["message", "replyOrigin"];
  static values = {type: String, target: String, parent: String}

  create(event) {
    // Elements needed to verify templates in DOM
    const messageEl = this.messageTarget;
    const parentId = (this.hasParentValue) ? this.parentValue : messageEl.dataset.templateMessageId;
    const originId = messageEl.dataset.templateMessageId;
    const messageType = this.typeValue;
    const firstLevel = (messageType == "reply");
    const targetNodeId = this.targetValue;

    // If component already exist in the DOM, erases and reuse it.
    const componentId = `${targetNodeId}-component`;
    let componentEl = document.getElementById(componentId);

    if (componentEl) {
      const textAreaEl = componentEl.querySelector('[data-template-target="textarea"]');
      textAreaEl.innerHTML = ""
      const formEl = componentEl.querySelector('[data-form-template-target]');

      if (messageEl.dataset.templateAiEnabled === 'true') {
        this._setParentIdOnForm(event.target, textAreaEl);
      }

      return this._focusTextArea(formEl, textAreaEl, firstLevel);
    }

    // copy form template and set set attributes.
    const template = document.getElementById("reply-form-template");
    const programId = messageEl.dataset.templateProgramId;
    const mentionsNoMatchTranslation = template.dataset.mentionsNoMatchTranslation;

    componentEl = template.content.firstElementChild.cloneNode(true);
    componentEl.id = componentId;
    componentEl.action = `/programs/${programId}/messages`;
    componentEl.setAttribute("origin-id", originId);

    // Set class attributes for second reply form types
    if (!firstLevel) {
      componentEl.classList.add("mt-4", "mx-6")
    }

    // Set stimulus controller values for enabled mentions.
    const textAreaEl = componentEl.querySelector('[data-template-target="textarea"]');

    if (messageEl.dataset.templateMentionsEnabled === 'true') {
      textAreaEl.dataset.richTextMentionsUrlValue = `/programs/${programId}/mentions.json`;
      textAreaEl.dataset.controller = "rich-text-mentions rich-text-emoji"
      textAreaEl.dataset.richTextMentionsTarget = "field";
      textAreaEl.dataset.richTextMentionsNoMatchValue = mentionsNoMatchTranslation;
    } else {
      componentEl.dataset.controller = "messages-disable-button";
    }

    if (messageEl.dataset.templateAiEnabled === 'true') {
      this._setParentIdOnForm(event.target, textAreaEl);
      textAreaEl.dataset.controller = `${textAreaEl.dataset.controller} rich-text-ai`;
      textAreaEl.dataset.richTextAiTarget = "field";
      textAreaEl.dataset.richTextAiMenuId = "rich-text-ai-menu";
      textAreaEl.dataset.richTextAiPath = "/rich_text_ai";
    }

    // insert new component into DOM
    const targetNode = document.getElementById(`${targetNodeId}`);
    targetNode.insertAdjacentElement("afterbegin", componentEl);

    // set hidden fields for new form and auto mentions on lower level replies, must be after its placed into DOM
    const formEl = componentEl.querySelector('[data-form-template-target]');
    formEl.children.namedItem("messages_new_form[parent_id]").value = parentId;
    formEl.children.namedItem("messages_new_form[type]").value = messageType;
    if (!firstLevel) {
      formEl.setAttribute("data-rich-text-auto-mentions", "form");
    }

    //set custom ids for trix input and textarea copied from template
    const trixInputEl = textAreaEl.inputElement;
    trixInputEl.id = `trix_input_${targetNodeId}`;
    textAreaEl.id = `trix_textarea_${targetNodeId}`;
    textAreaEl.setAttribute("input", `trix_input_${targetNodeId}`);

    this._focusTextArea(formEl, textAreaEl, firstLevel);
  }

  _setParentIdOnForm(target, textAreaEl) {
    let messageFormId;
    let messageSourceForm = target.closest("[id^=js-reply-]");

    if (messageSourceForm) {
      messageFormId = messageSourceForm.id;
    } else if (target.closest("[id^=js-message-]")) {
      messageFormId = target.closest("[id^=js-message-]").id;
    }

    textAreaEl.dataset.aiParentId = messageFormId.split('-').slice(-1)[0];
  }

  // hide and show relevent items, and focus DOM on the textarea.
  _focusTextArea(formEl, textAreaEl, firstLevel) {
    if(firstLevel){
      this.replyOriginTarget.classList.add("hidden");
    }
    formEl.classList.remove("hidden");

    textAreaEl.focus()
  }

}
